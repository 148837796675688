
    @import "config.scss";
 
.container {
  margin-bottom: 2rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.principal {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  flex: 0 0 auto;

  a {
    width: 100%;
    height: 100%;
  }
}

.second {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  a {
    width: 100%;
    height: 100%;
  }

  @include for-desktop-up {
    flex-direction: row;
  }
}

.secondBanners {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  flex: 1;
}

.img {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: relative;

  a {
    display: flex;
    width: 100%;
    height: 100%;
  }

  img {
    height: 100%;
    min-height: 220px;
    max-width: 100vw;
    width: 100%;
    object-fit: fill;
  }
}
